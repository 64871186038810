<template>
	<div id="landing">
		<v-row no-gutters class="main align-md-center" id="top">
			<v-col cols="12" class="main-right">
				<div class="main-intro pa-3 pa-md-5 pt-8 pt-md-2 text-center white--text mx-auto" style="max-width: 1500px;">
					<v-avatar :size="($vuetify.breakpoint.smAndDown) ? '25vh' : '30vh'" class="img-border elevation-5 mb-md-5"><v-img src="/home-dog.jpg" alt="Dog looking out, with hope, through a window." /></v-avatar>
					<h1 class="headline main-title text-md-h3 mt-5 font-weight-medium shadowed">Would anyone know if you never <br class="hidden-sm-and-down"> made it home last night?</h1>
					<p class="subtitle-1 text-md-h5 font-weight-medium shadowed mt-3 mt-md-5 mb-md-2 lh-14">We send texts to your phone to <br class="hidden-md-and-up" /> make sure you&rsquo;re okay.</p>
					<p class="subtitle-1 text-md-h5 font-weight-medium shadowed mb-0 lh-14">If you don&rsquo;t reply, we alert those who<br class="hidden-md-and-up" /> care about you so they can help.</p>
					<p class="mt-6 mt-md-10 mb-5">
						<v-btn rounded color="primary" @click.native="$vuetify.goTo('#how',{ container: 'body' }); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Hero - See How it Works' }});" :large="$vuetify.breakpoint.mdAndUp" class="shadowed px-10 mb-5 mb-md-0 mr-md-6">See How it Works</v-btn>
						<v-btn rounded color="primary" to="/signup" :large="$vuetify.breakpoint.mdAndUp" @click="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Hero - Get Started' }});" class="shadowed px-10">Get Started for Free</v-btn>
					</p>
				</div>
			</v-col>
		</v-row>
		<div class="primary bg-wavelines-primary pb-lg-10">
			<div class="ma-0 hidden-sm-and-down text-center"><v-btn @click.native="$vuetify.goTo('#how',{ container: 'body' }); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Hero - Down Chevron Clicked' }});" outlined rounded color="primary" style="margin-top: -100px;" class="mx-auto">
				<v-icon color="white">fa fa-chevron-down</v-icon>
			</v-btn></div>
			<div class="mx-auto pb-10" style="max-width: 1300px;">
				
				<div class="text-center mb-3 mb-lg-10 white--text">
					<h2 class="display-1 mt-0 pt-10 mt-md-0 mb-1  text-center text-md-h3 shadowed" id="how">How It Works</h2>
				</div>

				<div class="px-2 px-md-3">
					<v-row>
						<v-col cols="4" offset-lg="1" class="hidden-sm-and-down">
							<div class="phone small mx-auto">
								<div class="chat chat-flex elevation-3 mx-auto body-2 fill-height" v-intersect="handleMenuToggle">
									<div class="chat-header">
										<span class="d-block text-center subtitle-1 font-weight-medium">
											<span class="caption"><v-icon color="primary" x-small>fa fa-chevron-left</v-icon> Messages</span>
											I'm Okay
											<small class="caption">Contact</small>
										</span>
									</div>
									<div class="yours messages mt-0" ref="msg1">
										<div class="message last">
											<span class="d-block mb-2">I'm Okay App Check-In:</span>
											Just checking in... reply with any message (ex: "k") to let us know you're okay!
										</div>
									</div>
									<div class="mine messages mt-0" ref="msg2">
										<div class="message last">
										k
										</div> 
										<span class="caption grey--text pl-1">(that's all we need!)</span>
									</div>
									<div class="yours messages mt-0 pb-0" ref="msg1">
										<div class="message last">
											<span class="d-block mb-2">I'm Okay App Check-In:</span>
											Just checking in... reply with any message (ex: "k") to let us know you're okay!
										</div>
									</div>
									<div class="yours messages mt-0" ref="msg3">
										<span class="caption grey--text pl-1">(an hour or more has passsed)</span>
										<div class="message last wide mt-2" ref="msg3extra">
											<span class="d-block mb-2">Are you okay? You failed to check-in!</span>
											If we do not hear from you within 15 minutes, we will notify your emergency contacts.
										</div>
									</div>
									<div class="yours messages mt-0 hidden" ref="msg4">
										<div class="message last wide">
											<span class="d-block mb-2">I'm Okay App ALERT:</span>
											<span class="d-block mb-2">We have notified your emergency contacts that you missed your check-in.</span>
											Reply with any message (ex: "k") to let your contacts know you're okay.
										</div>
									</div>
								</div>
							</div>
						</v-col>
						<v-col cols="12" md="7" lg="6" class="">
							<div class="text-center mt-8 mt-lg-8">
								<v-card class="elevation-3">
									<v-card-text class="secondary--text">
										<v-avatar class="img-borde white" style="margin-top: -55px;">
											<v-icon color="primary" large>fa fa-comment-dots</v-icon>
										</v-avatar>
										<h2 class="lh-17 subtitle-1 text-md-h5 sm-bold" style="margin-top: -10px;">We Send Check-In Texts to Your Phone</h2> 
										<div class="d-flex align-center mt-md-2 justify-center">
											<p class="body-2 text-md-subtitle-1 lh-12 mb-0">
												You choose which days and times we should check in on you.<br class="hidden-sm-and-down">
												Receive as many as three each day or just once per week.
											</p>
										</div>
									</v-card-text>
								</v-card>
							</div>
							<div class="text-center mt-9">
								<v-card class="elevation-3">
									<v-card-text class="secondary--text">
										<v-avatar class="img-borde white" style="margin-top: -55px;">
											<v-icon color="success" large>fa fa-comment-dots</v-icon>
										</v-avatar>
										<h2 class="lh-17 subtitle-1 text-md-h5 sm-bold" style="margin-top: -10px;">You Reply to Let Us Know You're Okay</h2> 
										<div class="d-flex align-center mt-md-2 justify-center">
											<p class="body-2 text-md-subtitle-1 lh-12 mb-0">
												A simple &ldquo;k&rdquo; lets us know you're alright. We&rsquo;ll wait<br class="hidden-sm-and-down">
												an hour (or more, if you choose) for your reply.
											</p>
										</div>
									</v-card-text>
								</v-card>
							</div>
							<div class="text-center mt-9">
								<v-card class="elevation-3">
									<v-card-text class="secondary--text">
										<v-avatar class="img-borde white" style="margin-top: -55px;">
											<v-icon color="error" large>fa fa-comment-medical</v-icon>
										</v-avatar>
										<h2 class="lh-17 subtitle-1 text-md-h5 sm-bold" style="margin-top: -10px;">If You Don't Respond, We Take Action</h2> 
										<div class="d-flex align-center mt-md-2 justify-center">
											<p class="body-2 text-md-subtitle-1 lh-12 mb-0">
												We&rsquo;ll send one last reminder. After 15 minutes, we&rsquo;ll<br class="hidden-sm-and-down">
												start reaching out to your loved ones for help.
											</p>
										</div>
									</v-card-text>
								</v-card>
							</div>
							<p class="mt-5 mt-md-8 text-center"><v-btn rounded large color="white" class="px-10" @click.native="$vuetify.goTo('#next',{ container: 'body', offset: 30 }); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: How It Works - See What Happens Next' }});">See What Happens Next <v-icon color="primary" class="ml-2" small>fas fa-chevron-down</v-icon></v-btn></p>
						</v-col>
					</v-row>
				</div>
			</div>
		</div>
		
		<div class="white--text bg-wavelines-secondary pb-5 pb-md-10">
			<div class="mx-auto pb-10" style="max-width: 1300px;">
				
				<div class="text-center mb-8 mb-lg-15 pb-0" id="next">
					<v-avatar style="margin-top: -30px;" size="80" color="#0d2a47"><v-icon color="white" large>fa fa-exclamation-triangle</v-icon></v-avatar>
					<h2 class="display-1 pt-0 mb-1 text-center shadowed text-md-h3" id="how">In Case Of Emergency</h2>
					<p class="title mb-0 lh-14 text-center mx-auto shadowed mt-2 mb-0" style="max-width: 600px;">
						What happens if you don&rsquo;t respond<br class="hidden-md-and-up" /> to a check-in?
					</p>

				</div>

				<div class="secondary--text px-3 mb-0">
					<v-row>
						<v-col cols="12" md="7" lg="6" offset-lg="1" >
							<div class="text-center mt-5">
								<v-card class="elevation-3">
									<v-card-text class="secondary--text">
										<v-avatar class="img-borde white" style="margin-top: -55px;">
											<v-icon color="primary" size="28">fa fa-users</v-icon>
										</v-avatar>
										<h2 class="lh-17 subtitle-1 text-md-h5 sm-bold" style="margin-top: -10px;">We Alert Your Emergency Contacts</h2> 
										<div class="d-flex align-center mt-md-2 justify-center">
											<p class="body-2 text-md-subtitle-1 lh-12 mb-0">
												Add up to 5 people who you would like for us<br class="hidden-sm-and-down">
												to notify should you fail to check-in.
											</p>
										</div>
									</v-card-text>
								</v-card>
							</div>
							<div class="text-center mt-9">
								<v-card class="elevation-3">
									<v-card-text class="secondary--text">
										<v-avatar class="img-borde white" style="margin-top: -55px;">
											<v-icon color="error" size="30">fa fa-file</v-icon>
										</v-avatar>
										<h2 class="lh-17 subtitle-1 text-md-h5 sm-bold" style="margin-top: -10px;">An Alert Page is Created for You</h2> 
										<div class="d-flex align-center mt-md-2 justify-center">
											<p class="body-2 text-md-subtitle-1 lh-12 mb-0">
												This page can be shared and provides your contact information<br class="hidden-sm-and-down">
												as well as your last-known login time and location.
											</p>
										</div>
										<p class="mt-4 mb-0"><v-btn rounded small color="secondary" outlined class="px-5" to="/alertdemo" target="_blank" @click.native="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: IcoE - See Example Alert Page' }});">See an Example Alert Page</v-btn></p>
									</v-card-text>
								</v-card>
							</div>
							<div class="text-center mt-9">
								<v-card class="elevation-3">
									<v-card-text class="secondary--text">
										<v-avatar class="img-borde white" style="margin-top: -55px;">
											<v-icon color="success" size="34">fa fa-notes-medical</v-icon>
										</v-avatar>
										<h2 class="lh-17 subtitle-1 text-md-h5 sm-bold" style="margin-top: -10px;">We Share Your Emergency Notes</h2> 
										<div class="d-flex align-center mt-md-2 justify-center">
											<p class="body-2 text-md-subtitle-1 lh-12 mb-0">
												You can provide important information for us to share with your<br class="hidden-sm-and-down">
												contacts, including medical information, family &amp; pet care, and more.
											</p>
										</div>
										<p class="mt-4 mb-0"><v-btn rounded small color="secondary" outlined class="px-5" @click.native="$vuetify.goTo('#features',{ container: 'body', offset: 80 }); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: IcoE - Notes Learn More' }});">Learn More</v-btn></p>
									</v-card-text>
								</v-card>
							</div>
						</v-col>
						<v-col cols="4" class="hidden-sm-and-down">
							<div class="phone mx-auto small ml-lg-15">
								<div class="chat chat-flex elevation-3 mx-auto body-2 fill-height">
									<div class="chat-header">
										<span class="d-block text-center subtitle-1 font-weight-medium">
											<span class="caption"><v-icon color="primary" x-small>fa fa-chevron-left</v-icon> Messages</span>
											I'm Okay
											<small class="caption">Contact</small>
										</span>
									</div>
									<div class="yours messages mt-0 mb-2">
										<p class="subtitle-1 grey--text pt-0 mb-0 secondary--text font-weight-medium pl-1">What you see:</p>
										<div class="message last wide">
											<span class="d-block mb-2">I'm Okay App ALERT:</span>
											<span class="d-block mb-2">We have notified your emergency contacts that you missed your check-in.</span>
											Reply with any message (ex: "k") to let us all know you're okay.
										</div>
									</div>
									<v-divider />
									<div class="yours messages mt-0">
										<p class="subtitle-1 grey--text pt-3 mb-1 secondary--text font-weight-medium pl-1">What your contacts see:</p>
										<div class="message last wide">
											<span class="d-block mb-2">Alex has not responded to their check-in and may need your help.</span>
											<span class="d-block mb-2">We created a page with important information they wished to share with you should they fail to check-in on time:</span>
											<span class="primary--text">https://imokay.me/alert/ad5aa12469</span>
										</div>
									</div>
									<p class="subtitle-2 grey--text pt-0 text-center mb-1 primary--text font-weight-medium px-3 lh-12">
										If you respond at any time, we'll let your contacts know you're okay.
									</p>
								</div>
							</div>
						</v-col>
					</v-row>
				</div>

			</div>
		</div>

		<div class="secondary bbg-wavelines-secondary-dark text-center pt-10">
			<v-row>
				<v-col cols="12" class="px-0 white--text">
				<div class="mx-auto mb-10 text-center pb-5" style="max-width: 1200px;">
					<v-avatar style="margin-top: -85px;" size="80" color="secondary"><v-icon color="primary" size="40">fas fa-check-double</v-icon></v-avatar>
					<h2 class="display-1 pt-2 mb-1 text-center text-md-h3 shadowed" id="sign-up">Get Started</h2>
					<p class="title lh-15 text-center mx-auto mt-1 mb-0 shadowed" style="max-width: 800px;">
						We&rsquo;re ready to have your back.
					</p>
					<v-row dense class="mx-3 mb-5 mt-5">
						<v-col cols="12" md="12" class="text-center">
							<p class="subtitle-1 font-weight-medium">
								<v-icon small color="primary" class="pr-1">fa fa-dot-circle</v-icon> No Card Needed to Start<br>
								<v-icon small color="primary" class="pr-1">fa fa-dot-circle</v-icon> Try a 20-day Trial Period for Free<br>
								<v-icon small color="primary" class="pr-1">fa fa-dot-circle</v-icon> No Added Fees - All Features Included<br>
							</p>
							<p class="text-h3 mt-5 mb-0 primary--text font-weight-bold shadowed">Only $3/month</p>
							<p class="body-2">Cancel Anytime - Instantly, with no hassle or fees.</p>
							<p class="mt-7 mb-3"><v-btn large color="primary" class="px-10 shadowed" to="/signup" @click.native="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Signup 1 - Sign Up Clicked' }});" rounded>Get Started For Free</v-btn></p>
						</v-col>
					</v-row>
				</div>
			</v-col>
			</v-row>
		</div>

		<div class="white--text primary bg-wavelines-primary pb-15 px-md-3">
			<div class="mx-auto pb-0 pb-lg-10 mb-5 mb-md-0" style="max-width: 1300px;">
				
				<div class="text-center mb-8" id="next">
					<v-avatar style="margin-top: -35px;" size="80" color="primary"><v-icon color="white" size="40">fa fa-cogs</v-icon></v-avatar>
					<h2 class="display-1 pt-0 mb-1 text-center text-md-h3 shadowed" id="features">Features</h2>
					<p class="title lh-15 d-none text-center mx-auto mt-2 mb-0 shadowed" style="max-width: 800px;">
						<!-- Ensure vital information gets shared when others need it the most. -->
						More Ways to Help Others Help You
						<br>
						<!-- Whether you're in an accident or have simply lost your phone while away,<br>
						emergency notes automatically provide help to your support system in your absence. -->
					</p>
				</div>
				<div class="px-2 px-md-0">
					<v-row dense>
						<v-col cols="12" md="6">
							<v-card class="elevation-2 fill-height" style="background-size: cover; background-image: url(/home-emergency.jpg)">
								<v-card-text class="pa-lg-10">
									<div class="en-dark text-center white--text pa-3">
										<h3 class="text-h4 text-md-h3 mt-2 shadowed">Emergency Notes</h3>
										<p class="text-h5 shadowed mt-4 lh-16">Ensure vital information is shared when <br class="hidden-sm-and-down" /> others need it the most.</p>
										<p class="subtitle-1 font-weight-medium shadowed mt-4 lh-16">Medical &bull; Family &bull; Pets &bull; Property</p>
									</div>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="12" md="6">
							<v-card min-height="400" class="elevation-2">
								<v-card-text class="pa-5 px-md-10 secondary--text">
									<p class="title text-md-h5 mt-1 text-center text-md-left lh-15">
										Accidents, flight delays, or simply losing your phone <span class="hidden-sm-and-down">while you're away</span> can leave you in a tough spot.
									</p>
									<p class="title text-center text-md-left lh-15 hidden-md-and-up">
										Emergency notes provide helpful information to support system in your absence.
									</p>
									<p class="text-h5 text-center text-md-left lh-16 hidden-sm-and-down">
										Emergency notes provide helpful information to your support system in your absence.
									</p>
									<v-divider />
									<div class="mt-5 d-flex align-center">
										<div>
											<v-icon color="primary">fa fa-sync</v-icon>
										</div>
										<div>
											<p class="subtitle-1 pl-3 lh-13 mb-1">Automatically sent to your emergency contacts if you fail to check-in.</p>
										</div>
									</div>
									<div class="mt-3 d-flex align-center">
										<div>
											<v-icon color="primary" size="22">fa fa-eye-slash</v-icon>
										</div>
										<div>
											<p class="subtitle-1 pl-2 lh-13 mb-1">Can only be viewed by your contacts if you're on alert.</p>
										</div>
									</div>
									<div class="mt-3 d-flex align-center">
										<div>
											<v-icon color="primary" class="pl-1">fa fa-lock</v-icon>
										</div>
										<div>
											<p class="subtitle-1 pl-3 lh-13 mb-1">Text authentication <span class="hidden-sm-and-down">is always</span> required to view.</p>
										</div>
									</div>
									<div class="mt-3 d-flex align-center">
										<div>
											<v-icon color="primary">fa fa-comment</v-icon>
										</div>
										<div>
											<p class="subtitle-1 pl-3 lh-13 mb-1">Send to anyone at any time using your phone or our app.</p>
										</div>
									</div>
									<v-divider class="mt-5" />
									<p class="mt-4 text-center mb-0"><v-btn rounded color="primary" class="px-5 shadowed" @click.native="showNotesExtra=!showNotesExtra; $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Features - Emergency Notes Learn More' }});">{{ (showNotesExtra) ? 'Show Less' : 'Learn More' }}</v-btn></p>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="12" v-if="showNotesExtra">
							<v-card color="secondary" class="elevation-3 mb-2 mt-md-1 mb-md-3 bg-wavelines-secondary">
								<v-card-text class="white--text pa-2">
									<p class="mt-2 text-center shadowed subtitle-1 mb-2 mb-md-2 lh-14 text-md-h6 font-weight-medium">It can be overwhelming deciding what to share with others should you need help.</p>
									<p class="text-center shadowed subtitle-1 mb-2 lh-14 text-md-h6 font-weight-medium">We've created the following templates<br class="hidden-md-and-up" /> to help you get started:</p>
								</v-card-text>
							</v-card>
							<v-row dense class="justify-center mb-1 mb-md-2">
								<v-col cols="12" md="3">
									<v-card class="elevation-3 fill-height" hover>
										<div class="notes-card">
											<v-img :height="($vuetify.breakpoint.smAndDown) ? 150 : 200" src="/home-medical.jpg" />
											<p class="mb-0 pl-4 headline font-weight-medium shadowed notes-card-title">Medical Information</p>
										</div>
										<v-card-text class="secondary--text subtitle-1 lh-14">
											Keep your contacts informed of any medical conditions or allergies you may have and provider information.
										</v-card-text>
										<v-divider />
										<v-card-actions class="justify-center">
											<v-btn text small rounded outlined color="primary" @click.native="notesPreview=true; $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Notes - View Example Clicked' }});" class="px-10">See Example</v-btn>
										</v-card-actions>
									</v-card>
								</v-col>
								<v-col cols="12" md="3">
									<v-card class="elevation-3 fill-height">
										<div class="notes-card">
											<v-img :height="($vuetify.breakpoint.smAndDown) ? 150 : 200" src="/home-family.jpg" />
											<p class="mb-0 pl-4 headline font-weight-medium shadowed notes-card-title">Child &amp; Family Care</p>
										</div>
										<v-card-text class="secondary--text subtitle-1 lh-14">
											Whether you're a single parent or taking care of a loved one, provide information for those who depend on you.
										</v-card-text>
										<v-divider />
										<v-card-actions class="justify-center">
											<v-btn text small rounded outlined color="primary" @click.native="notesPreview=true; $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Notes - View Example Clicked' }});" class="px-10">See Example</v-btn>
										</v-card-actions>
									</v-card>
								</v-col>
								<v-col cols="12" md="3">
									<v-card class="elevation-3 fill-height">
										<div class="notes-card">
											<v-img :height="($vuetify.breakpoint.smAndDown) ? 150 : 200" src="/home-pet.jpg" />
											<p class="mb-0 pl-4 headline font-weight-medium shadowed notes-card-title">Pet Care</p>
										</div>
										<v-card-text class="secondary--text subtitle-1 lh-14">
											Let your contacts know how to feed and care for your pets while you're not there so they're okay too!
										</v-card-text>
										<v-divider />
										<v-card-actions class="justify-center">
											<v-btn text small rounded outlined color="primary" @click.native="notesPreview=true; $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Notes - View Example Clicked' }});" class="px-10">See Example</v-btn>
										</v-card-actions>
									</v-card>
								</v-col>
								<v-col cols="12" md="3">
									<v-card class="elevation-3 fill-height">
										<div class="notes-card">
											<v-img :height="($vuetify.breakpoint.smAndDown) ? 150 : 200" src="/home-property.jpg" />
											<p class="mb-0 pl-4 headline font-weight-medium shadowed notes-card-title">Property Notes</p>
										</div>
										<v-card-text class="secondary--text subtitle-1 lh-14">
											Add notes for accessing and caring for your home, like securing your valuables and watering your plants.
										</v-card-text>
										<v-divider />
										<v-card-actions class="justify-center">
											<v-btn text small rounded outlined color="primary" @click.native="notesPreview=true; $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Notes - View Example Clicked' }});" class="px-10">See Example</v-btn>
										</v-card-actions>
									</v-card>
								</v-col>
							</v-row>
							<v-card color="secondary" class="elevation-3 mb-0 mb-md-1 bg-wavelines-secondary">
								<v-card-text class="white--text pa-2">
									<p class="text-center shadowed subtitle-1 mt-2 mb-2 lh-14 text-md-h6 font-weight-medium">Want to share more? You can add as many emergency notes as you like!</p>
									<p class="text-center mt-3 mb-2"><v-btn class="px-5 shadowed" to="/support" color="primary" rounded @click.native="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Features - View Notes FAQs' }});">View Emergency Notes FAQs</v-btn></p>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="12" md="6">
							<v-card color="white" class="elevation-2">
								<v-card-text class="pa-5 px-md-10 secondary--text">
									<p class="text-h5 mt-1 text-center lh-16">
										<v-icon large color="primary">fa fa-bed</v-icon>
									</p>
									<p class="text-h5 mt-1 text-center lh-16 mb-1">
										Snooze Your Check-Ins
									</p>
									<p class="text-center subtitle-1 lh-13 mb-1">
										Unplugging for a few days? Great! Snooze your check-ins and they'll automatically resume when you're done.
									</p>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="12" md="6">
							<v-card color="white" class="elevation-2">
								<v-card-text class="pa-5 px-md-10 secondary--text">
									<p class="text-h5 mt-1 text-center lh-16">
										<v-icon large color="primary">fa fa-clock</v-icon>
									</p>
									<p class="text-h5 mt-1 text-center lh-16 mb-1">
										1-Time Check-Ins
									</p>
									<p class="text-center subtitle-1 lh-13 mb-1">
										Great for first dates and other stranger dangers. Add a one-time check-in on top of your existing schedule.
									</p>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="12">
							<v-row>
								<v-col cols="12" class="text-center pt-md-13">
									<v-card class="fill-height mt-9 mt-md-0 primary bg-wavelines-secondary">
										<v-card-text class="text-center white--text px-1 pb-0 pb-md-5">
											<v-avatar size="60" color="#0d2a47" class="img-border mb-3" style="margin-top: -47px;">
												<v-icon color="white" large>fa fa-lock</v-icon>
											</v-avatar>
											<p class="text-h5 mt-1 lh-15 font-weight-medium mb-0 shadowed">Privacy &amp; Security</p>
											<v-divider class="my-2" dark />
											<p class="body-2 text-md-subtitle-1 lh-13 py-1 px-5 mb-0">
												We treat all your information as private and sensitive. We've taken every precaution to shield it from unwanted eyes.
											</p>
											<v-divider class="my-2" dark />
											<p class="title text-center mb-2 pt-1 font-weight-medium text-md-h6 lh-17">How we protect your data:</p>
											<v-row no-gutters class="mx-auto" style="max-width: 600px;">
												<v-col cols="12" md="6">
													<p class="subtitle-1 text-center text-md-left pt-1 mb-1 font-weight-medium lh-17"><v-icon size="20" color="white" class="pr-1">fa fa-eye-slash</v-icon> Your Notes Remain Private</p>
													<p class="subtitle-1 text-center text-md-left mb-1 pt-1 font-weight-medium lh-17"><v-icon color="white" class="pr-1">fa fa-sms</v-icon> Text Authentication Required</p>
													<p class="subtitle-1 text-center text-md-left mb-1 pt-1 font-weight-medium lh-17"><v-icon color="white" class="pr-1">fa fa-server</v-icon> Limited Data Retention</p>
												</v-col>
												<v-col cols="12" md="6">
													<p class="subtitle-1 text-center text-md-left mb-1 pt-1 font-weight-medium lh-17"><v-icon color="white" class="pl-1 pr-2">fa fa-user</v-icon> Limited Identifiable Information</p>
													<p class="subtitle-1 text-center text-md-left mb-1 pt-1 font-weight-medium lh-17"><v-icon size="25" color="white" class="pr-1">fa fa-eye</v-icon> Access Records are Recorded</p>
													<p class="subtitle-1 text-center text-md-left mb-1 pt-1 font-weight-medium lh-17"><v-icon color="white" class="pl-1 pr-1">fa fa-shield</v-icon> All Stored Data is Encrypted</p>
												</v-col>
											</v-row>
											<v-divider class="mt-3 mb-2" dark />
											<p class="body-2 text-md-subtitle-1 lh-13 py-2 px-5 mb-0">
												These are just a few of the steps we take to safeguard your information.
											</p>
											<p class="text-center mb-1 mt-2">
												<v-btn color="primary" to="/support" @click.native="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Security - View Security FAQs' }});" rounded class="px-5 shadowed">View Privacy &amp; Security FAQs</v-btn>
											</p>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</div>
			</div>
		</div>

		<div class="secondary text-center pt-10">
			<v-row>
				<v-col cols="12" class="px-0 white--text">
				<div class="mx-auto text-center pb-5" style="max-width: 1200px;">
					<v-avatar style="margin-top: -85px;" size="80" color="secondary"><v-icon color="primary" size="40">fas fa-check-double</v-icon></v-avatar>
					<h2 class="display-1 pt-2 mb-1 text-center text-md-h3 shadowed" id="sign-up">Get Started</h2>
					<p class="title lh-15 text-center mx-auto mt-1 mb-0 shadowed" style="max-width: 800px;">
						We&rsquo;re ready to have your back.
					</p>
					<v-row dense class="mx-3 mb-5 mt-5">
						<v-col cols="12" md="12" class="text-center">
							<p class="subtitle-1 font-weight-medium">
								<v-icon small color="primary" class="pr-1">fa fa-dot-circle</v-icon> No Card Needed to Start<br>
								<v-icon small color="primary" class="pr-1">fa fa-dot-circle</v-icon> Try a 20-day Trial Period for Free<br>
								<v-icon small color="primary" class="pr-1">fa fa-dot-circle</v-icon> No Added Fees - All Features Included<br>
							</p>
							<p class="text-h3 mt-5 mb-0 primary--text font-weight-bold shadowed">Only $3/month</p>
							<p class="body-2">Cancel Anytime - Instantly, with no hassle or fees.</p>
							<p class="mt-7 mb-3"><v-btn large color="primary" class="px-10 shadowed" to="/signup" @click.native="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Home: Sign Up 2 - Sign Up Clicked' }});" rounded>Get Started For Free</v-btn></p>
						</v-col>
					</v-row>
				</div>
			</v-col>
			</v-row>
		</div>
		<NotesPreview v-if="notesPreview" @closed="notesPreview=false" />
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Home',
	data: () => ({
		images: [
			'https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80',
			'https://images.unsplash.com/photo-1554734867-bf3c00a49371?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
			'https://images.unsplash.com/photo-1598134493179-51332e56807f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
		],
		captions: [
			"Who would know if you never <br>made it home last night?",
			"Do your friends know how to<br> contact your family?",
			"Your flight's delayed.<br> Who will feed your dog?",
		],
		captionsSmall: [
			"Who would know if you never made it home last night?",
			"Do your friends know how to contact your family?",
			"Your flight's delayed. Who will feed your dog?",
		],
		subTitles: [
			"We send check-in messages to your phone to make sure you're okay.",
			"We relay important information to your emergency contacts.",
			"We share critical instructions with your emergency contacts.",
		],
		subSubTitles: [
			"If you don't reply, we alert those who care about you so they can help.",
			"Designed to help others help you in your time of need.",
			"Because all your loved ones - pets and plants included - matter.",
		],
		moreNotes: false,
		moreSec: false,
		notesPreview: false,
		slideIdx: 0,
		showNotesExtra: false,
		interval: null,
		step: 1
	}),
	components: {
        NotesPreview: () => import('@/components/home/NotesPreview'),
	},
	computed: {
        ...mapState(['user']),
	},
	methods: {
		initData(){
			if(this.user && this.user.uuid){
				this.$router.push('/app');
			}
			this.checkHash();
		},
		checkHash(){
			if(location.href.indexOf('#how') !== -1){
				this.$vuetify.goTo('#how',{ container: 'body' })
			}
			if(location.href.indexOf('#top') !== -1){
				this.$vuetify.goTo('#top', { container: 'body' })
			}
			if(location.href.indexOf('#features') !== -1){
				this.$vuetify.goTo('#features',{ container: 'body', offset: 80 })
			}
		},
		handleStep(){
			this.$refs.step1.$el.classList.add('step-faded');
			this.$refs.step2.$el.classList.add('step-faded');
			this.$refs.step3.$el.classList.add('step-faded');
			this.$refs.step4.$el.classList.add('step-faded');
			setTimeout(() => {
				if(this.step == 1){
					this.$refs.step1.$el.classList.remove('step-faded');
				}
				if(this.step==2){
					this.$refs.msg2.classList.remove('hidden');
					this.$refs.step2.$el.classList.remove('step-faded');
				}
				if(this.step>2){
					this.$refs.msg3.classList.remove('hidden');
					this.$refs.step3.$el.classList.remove('step-faded');
				}
			}, 100);
		},
		startHow(e){
			if(!e[0].isIntersecting || 1==1){
				return;
			}
			setTimeout(() => {
				this.$refs.msg1.classList.remove('hidden');
				this.$refs.step1.$el.classList.remove('step-faded');
			}, 1000);
			setTimeout(() => {
				this.$refs.msg2.classList.remove('hidden');
				this.$refs.step2.$el.classList.remove('step-faded');
			}, 5000);
			setTimeout(() => {
				this.$refs.msg3.classList.remove('hidden');
				this.$refs.step3.$el.classList.remove('step-faded');
			}, 10000);
			setTimeout(() => {
				this.$refs.msg3extra.classList.remove('last');
				this.$refs.msg4.classList.remove('hidden');
				this.$refs.step4.$el.classList.remove('step-faded');
			}, 15000);
		},
		startLoop(){
			this.interval = setInterval(() => {
				if(this.slideIdx == this.images.length - 1){
					this.slideIdx = 0;
				}else{
					++ this.slideIdx;
				}
			}, 10000);
		},
		handleSlideChange(n){
			clearInterval(this.interval);
			this.slideIdx = n;
		},
		handleMenuToggle(e){
			if(e[0].isIntersecting){
				//document.getElementById('top-nav').classList.remove('landing-top');
			}else{
				//document.getElementById('top-nav').classList.add('landing-top');
			}
		}
	},
	beforeDestroy(){
	},
	mounted(){
		this.initData();
	},
	watch: {
	}
}
</script>